<template>
  <div class="mb-1">
    <hr />

    <!-- Filters -->
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col
            :key="i"
            v-for="(item, i) in ['Project', 'Queue', 'Agent', 'RemoteNumber']"
          >
            <b-form-group v-if="item == 'Project'">
              <v-select
                v-model="filter_values.selectedProjects"
                multiple
                :placeholder="lang('t_r' + item)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="projects"
              />
            </b-form-group>
            <b-form-group v-if="item == 'Queue'">
              <v-select
                v-model="filter_values.selectedQueues"
                multiple
                :placeholder="lang('t_r' + item)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="queues"
              />
            </b-form-group>

            <b-form-group v-if="item == 'Agent'">
              <v-select
                v-model="filter_values.selectedAgents"
                multiple
                :placeholder="lang('t_r' + item)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="username"
                :reduce="(item) => item.username"
                :options="agents"
              />
            </b-form-group>
            <b-form-group v-if="item == 'RemoteNumber'">
              <b-form-input
                v-model="filter_values.selectedNumber"
                :placeholder="lang('t_r' + item)"
                @update="
                  () => {
                    filter_values.selectedNumber = filter_values.selectedNumber
                      .split(' ')
                      .join('');
                  }
                "
                @input="
                  () => {
                    filter_values.selectedNumber = filter_values.selectedNumber
                      .split(' ')
                      .join('');
                  }
                "
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col>
            <div class="d-flex align-items-center gap-3">
              <b-form-checkbox v-model="filter_values.is_inbound">
                {{ lang("t_inbound") }}
              </b-form-checkbox>

              <b-form-checkbox class="ml-3" v-model="filter_values.is_outbound">
                {{ lang("t_outbound") }}
              </b-form-checkbox>
              <b-form-checkbox class="ml-3" v-model="filter_values.is_dialer">
                {{ lang("t_dialer") }}
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <!-- Klasör formatı -->
      <b-col md="12" class="mb-1">
        <draggable
          :list="folder_format_columns"
          :group="{ name: 'g2', pull: 'clone', put: false }"
          :clone="cloneItem2"
        >
          <b-badge
            class="mr-50"
            v-for="(item, i) in folder_format_columns"
            :key="i"
            variant="primary"
          >
            {{ item.display_name }}
          </b-badge>
        </draggable>
      </b-col>
      <b-col md="12" class="pb-0">
        <draggable
          class="dragArea list-group card"
          tag="ul"
          :list="list3"
          :group="{ name: 'g2', type: 'folder_format' }"
          @add="changeItemId2"
        >
          <b-form-input
            v-model="currentData.folder_format"
            id="basicInput"
            :placeholder="lang('t_folderType')"
          />
        </draggable>
      </b-col>
      <!-- İsim formatı -->
      <b-col md="12" class="mb-1">
        <draggable
          :list="columns"
          :group="{ name: 'g1', pull: 'clone', put: false }"
          :clone="cloneItem"
        >
          <b-badge
            class="mr-50"
            v-for="(item, i) in columns"
            :key="i"
            variant="primary"
          >
            {{ item.display_name }}
          </b-badge>
        </draggable>
      </b-col>
      <b-col md="12" class="pb-0">
        <draggable
          class="dragArea list-group card"
          tag="ul"
          :list="list2"
          :group="{ name: 'g1', type: 'voice_export' }"
          @add="changeItemId"
        >
          <b-form-input
            v-model="currentData.name_format"
            id="basicInput"
            :placeholder="lang('t_nameType')"
          />
        </draggable>
      </b-col>

      <!-- Hedef Email -->
      <b-col md="12" class="pb-0">
        <b-form-group
          :label="lang('t_email')"
          label-for="helperInput"
          :description="lang('t_whenTheFilesAreReadyYouWillbeSentAnEMail')"
        >
          <b-form-input
            v-model="currentData.emails"
            id="helperInput"
            :placeholder="
              lang('t_youCanAddCommaBetweenEmailAddressesToAddMore')
            "
            trim
          />
        </b-form-group>
      </b-col>

      <!--  Email Subject -->
      <b-col v-if="currentData.put_as_folder" md="12" class="pb-0">
        <b-form-group :label="lang('t_subject')" label-for="helperInput">
          <b-form-input
            v-model="currentData.email_subject"
            :placeholder="lang('t_subject')"
            trim
          />
        </b-form-group>
      </b-col>

      <!--  Email Body -->
      <b-col v-if="currentData.put_as_folder" md="12" class="pb-0">
        <b-form-group :label="lang('t_rBody')" label-for="helperInput">
          <b-form-textarea
            v-model="currentData.email_body"
            :placeholder="lang('t_rBody')"
            trim
            rows="3"
          />
        </b-form-group>
      </b-col>

      <!-- Custom Meta Data -->
      <b-col md="12" class="pb-0 mt-1">
        <b-row>
          <b-col v-if="currentData.custom_meta_data">
            <b-form-group :label="lang('t_metaDataEndpoint')">
              <b-form-input
                v-model="currentData.meta_data_endpoint"
                id="custom_meta_data"
                :placeholder="''"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col v-if="currentData.custom_meta_data">
            <b-form-group :label="lang('t_metaDataRemotePath')">
              <b-form-input
                v-model="currentData.meta_data_path"
                id="meta_data_remote_path"
                :placeholder="'/root/voice_export'"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <!-- Zaman Damgası -->
      <b-col md="12" class="pb-0 mt-1 ml-1">
        <b-row>
          <b-form-checkbox v-model="currentData.timestamp">
            {{ lang("t_timestamp") }}
          </b-form-checkbox>
          <b-form-checkbox class="ml-3" v-model="currentData.put_ftp">
            {{ lang("t_putFtp") }}
          </b-form-checkbox>
          <b-form-checkbox
            v-if="currentData.put_ftp"
            class="ml-3"
            v-model="currentData.put_as_folder"
          >
            {{ lang("t_putAsFolder") }}
          </b-form-checkbox>
          <b-form-checkbox
            v-if="currentData.put_ftp"
            class="ml-3"
            v-model="currentData.custom_meta_data"
          >
            {{ lang("t_customMetaData") }}
          </b-form-checkbox>
        </b-row>
      </b-col>

      <!-- Zaman Damgası -->
      <b-col md="12" class="pb-0 mt-1">
        <b-row>
          <b-col v-if="currentData.put_ftp">
            <b-form-group :label="lang('t_provider')">
              <v-select
                v-model="currentData.ftp_provider"
                :placeholder="lang('t_provider')"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item"
                :options="ftp_providers"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="currentData.put_ftp">
            <b-form-group :label="lang('t_remotePath')">
              <b-form-input
                v-model="currentData.ftp_remote_path"
                id="remote_path"
                :placeholder="'/root/voice_export'"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <!-- Dosya Formatı Radio Button -->
      <b-col md="12" class="ml-1">
        <b-row v-if="currentData.put_ftp">
          <span class="mr-3">{{ lang("t_fileType") }}</span>
          <b-form-radio
            class="mr-2"
            v-model="currentData.format"
            name="some-radios3"
            value="wav"
          >
            WAV
          </b-form-radio>
          <b-form-radio
            class="mr-2"
            v-model="currentData.format"
            name="some-radios3"
            value="ogg"
          >
            OGG
          </b-form-radio>
          <b-form-radio
            class="mr-2"
            v-model="currentData.format"
            name="some-radios3"
            value="mp3"
          >
            MP3
          </b-form-radio>
        </b-row>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>



<script>
import {
  BBadge,
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BFormRadio,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import flatPickr from "vue-flatpickr-component";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
    BBadge,
    BCard,
    BCardText,
    BLink,
    BCardActions,
    flatPickr,
    BRow,
    BCol,
    BFormGroup,
    BCardCode,
    vSelect,
    BFormCheckbox,
    BFormInput,
    BFormRadio,
    BButton,
    BFormTextarea,
  },
  props: {
    currentData: {
      required: true,
      type: Object,
      default: () => ({
        // folder_format: "{{Year}}/{{Month}}/{{Day}}",
        // name_format: "{{Direction}}_{{BaseRemoteNumber}}_{{Agent}}_{{StartTime}}_{{InteractionID}}",
        // format: "ogg",
        // timestamp: false,
        // emails: "",
        // put_ftp: false,
        // ftp_provider: null,
        // ftp_remote_path: ''
        folder_format: "{{Year}}/{{Month}}/{{Day}}",
        // folder_format: "{{Day}}{{Month}}{{Year}}/{{Day}}",
        name_format: "{{Direction}}_{{BaseRemoteNumber}}_{{Agent}}_{{StartTime}}_{{InteractionID}}",
        format: "ogg",
        timestamp: false,
        emails: "",
        email_subject: "",
        email_body: "",
        put_ftp: false,
        put_as_folder: false,
        ftp_provider: null,
        ftp_remote_path: '',
        custom_meta_data: false,
        meta_data_endpoint: '',
        meta_data_path: '',
      }),
    },

    filter_values: {
      required: true,
      type: Object,
    },
  },

  data: () => ({
    lang: _l(window.localStorage.getItem("language") || "tr"),
    list2: [],
    list3: [],
    agents: [],
    projects: [],
    queues: [],
    ftp_providers: [],

    columns: [
      {
        internal_name: "StartTime",
        display_name: globalThis._lang("t_rStartTime"),
      },
      {
        internal_name: "BaseRemoteNumber",
        display_name: globalThis._lang("t_rBaseRemoteNumber"),
      },
      { internal_name: "Agent", display_name: globalThis._lang("t_rAgent") },
      {
        internal_name: "Direction",
        display_name: globalThis._lang("t_direction"),
      },
      {
        internal_name: "Verdict",
        display_name: globalThis._lang("t_rVerdict"),
      },
      {
        internal_name: "QueueName",
        display_name: globalThis._lang("t_queueName"),
      },
      {
        internal_name: "InteractionID",
        display_name: globalThis._lang("t_rInteractionID"),
      },
      {
        internal_name: "Müşteri No",
        display_name: globalThis._lang("t_customerId"),
      },
      {
        internal_name: "Adı Soyadı",
        display_name: globalThis._lang("t_rAdıSoyadı"),
      },
    ],
    folder_format_columns: [
      { internal_name: "Year", display_name: globalThis._lang("t_year") },
      { internal_name: "Month", display_name: globalThis._lang("t_month") },
      { internal_name: "Day", display_name: globalThis._lang("t_day") },
      { internal_name: "Agent", display_name: globalThis._lang("t_agent") },
    ],
  }),
  methods: {
    changeItemId(e) {
      console.log("changeItemId:", e); // e.to.__vue__.$attrs.group.type
      // e.item._underlying_vm_.parent = e.to.__vue__.$attrs.group.type;
    },
    changeItemId2(e) {
      console.log("changeItemId:", e); // e.to.__vue__.$attrs.group.type
      // e.item._underlying_vm_.parent = e.to.__vue__.$attrs.group.type;
    },
    cloneItem(item) {
      console.log(item);
      if (this.currentData.name_format.length > 0) {
        this.currentData.name_format += `_{{${item.internal_name}}}`;
      } else {
        this.currentData.name_format += `{{${item.internal_name}}}`;
      }
    },
    cloneItem2(item) {
      console.log(item);
      if (this.currentData.folder_format.length > 0) {
        this.currentData.folder_format += `/{{${item.internal_name}}}`;
      } else {
        this.currentData.folder_format += `{{${item.internal_name}}}`;
      }
    },
    GetFtpProviders: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/FTPProvider`)
      ).data;
      this.ftp_providers = response;
    },


    GetUsers: async function () {
      var response = (await this.$http_in.get(`v_export/v1/User`)).data;

      this.agents = response;
    },
    GetProjects: async function () {
      var response = (await this.$http_in.get(`v_export/v1/Project`)).data;

      this.projects = response;
    },
    GetQueues: async function () {
      var response = (await this.$http_in.get(`v_export/v1/Queue`)).data;

      this.queues = response;
    },
    VoiceExport: async function () {
      if (this.currentData.put_ftp && (this.currentData.ftp_remote_path == '' || this.currentData.ftp_provider == null)) {

      }

      this.filter_values.selectedNumber = this.filter_values.selectedNumber.split(',');
      this.$http_in.post(`v_export/v1/VoiceExport`, {
        filters: this.filter_values,
        info: this.currentData,
      });
      this.$swal({
        title: globalThis._lang("t_processSuccess"),
        text: "Export işlemi başlatıldı, işlem tamamlandığında ses dosyaları mail üzerinden iletilecektir.",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      this.filter_values.selectedNumber = '';
    },
  },
  mounted() {
    console.log("currentData", this.currentData);
    this.GetUsers();
    this.GetProjects();
    this.GetQueues();
    this.GetFtpProviders();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
